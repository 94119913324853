import React, { FunctionComponent } from "react";

const Coding: FunctionComponent = () => {
	return (
		<li>
			Coding
			<ul>
				<li>
					ASP.NET (.Net Framework and .Net Core) 
				</li>
				<li>
					C, C++ and C#
				</li>
				<li>
					HTML, CSS, Javascript and React.js
				</li>
				<li>
					Lua
				</li>
				<li>
					Python (Rarely)
				</li>
				<li>
					PHP (Rarely, not preferred)
				</li>
			</ul>
		</li>
	);
};

export default Coding;