import React, { FunctionComponent } from "react";

const ContentCreation: FunctionComponent = () => {
	return (
		<li>
		Content Creation
		<ul>
			<li>
				Publishing youtube videos
			</li>
			<li>
				Streaming live on twitch
			</li>
			<li>
				Games and game mods (i.e. GMOD gamemodes/addons)
			</li>
			</ul>
		</li>
	);
};

export default ContentCreation;