import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export interface NavBarState {
	PositionLocked: boolean;
};

const SetCookiesValue = (isNavLocked: boolean) => {
	Cookies.set("NavBarPositionLock", (isNavLocked).toString());
};

const initialState: NavBarState = {
	PositionLocked: Cookies.get("NavBarPositionLock") === "true",
};

export const NavBarSlice = createSlice({
	name: "NavBar",
	initialState,
	reducers: {
		TogglePositionLock: (state) => {
			SetCookiesValue(!state.PositionLocked);
			state.PositionLocked = !state.PositionLocked;
		},
		SetPositionLock: (state, action: PayloadAction<boolean>) => {
			SetCookiesValue(action.payload);
			state.PositionLocked = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { TogglePositionLock, SetPositionLock } = NavBarSlice.actions;

export default NavBarSlice.reducer;