import "./Bootstrap-Extension.scss";
import "bootstrap/dist/css/bootstrap.css";
import "./Custom.scss";

import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./Store/";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") as string;

ReactDOM.render(
    <Provider store={store}>
		<BrowserRouter basename={baseUrl}>
			<App />
		</BrowserRouter>
    </Provider>,
	document.getElementById("root")
);

registerServiceWorker();