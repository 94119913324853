import React, { FunctionComponent } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import SiteSettingsDropdown from "./SiteSettingsDropdown";
import DropdownLink from "./DropdownLink";
import { IOptionalClassName, ITitle } from "../../../Types";

interface INavDropdownButtonProps extends IOptionalClassName, ITitle {};

const NavDropdownButton: FunctionComponent<INavDropdownButtonProps> = ({ title,	className }) => {

    return (
        <DropdownButton
            title={title}
            id="dropdown-account-button"
            className={(className += " AccountDropdown")}
        >
            
            <DropdownLink to="/About" title="About" />
            <Dropdown.Item />
            <SiteSettingsDropdown />
        </DropdownButton>
    );
};

export default NavDropdownButton;
