import React, { FunctionComponent, useEffect } from "react";
import NavBar from "../NavBarComponents/NavBar";
import Footer from "../FooterComponents/Footer";
import Wrapper from "../WrapperComponents/Wrapper";
import $ from "jquery";
import { IReactNodeChildren } from "../../Types";


interface ILayoutProps extends IReactNodeChildren {}


const Layout: FunctionComponent<ILayoutProps> = ({ children }) => {

	useEffect(() => {
		let bodyObj = $("body");
		let htmlObj = $("html");
		if (bodyObj.hasClass("IngameUI")) {
			htmlObj.removeClass("IngameUI");
			bodyObj.removeClass("IngameUI");
		};
	}, []);

	return (
		<div>
			<NavBar title="Hello There" />
			<Wrapper>{children}</Wrapper>
			<Footer />
		</div>
	);
};

export default Layout;
