import React, { FunctionComponent } from "react";

const Gaming: FunctionComponent = () => {
	return (
		<li>
			Gaming
			<ul>
				<li>
					Escape From Tarkov
				</li>
				<li>
					Diablo 3 (Waiting for Diablo 4)
				</li>
				<li>
					Elite Dangerous
				</li>
				<li>
					9Dragons
				</li>
				<li>
					Terraria (Waiting for TModLoader support for 1.4*)
				</li>
				<li>
					GMOD ( Occasionally)
				</li>
			</ul>
		</li>
	);
};

export default Gaming;