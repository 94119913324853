import React, { FunctionComponent } from "react";
import { Container, Row, Col } from "react-bootstrap";

const SocialBar: FunctionComponent = () =>
{
	return (
		<Container id="Socials" className="bg-white-semi-transparent rounded-xl mw-90">
			<Row>
				<Col xs={{ span: 5, offset: 1 }} sm={{ span: 4, offset: 0 }} md={{ span: 4, offset: 0 }} lg={{ span: 2, offset: 1 }} xl={{ span: 2, offset: 1 }}>
					<a href="http://discord.krelsis.net">
						<div id="DiscordIcon" className="TransitionImage SocialIcon"> </div>
					</a>
				</Col>
				<Col xs={{ span: 5, offset: 0 }} sm={{ span: 4, offset: 0 }} md={{ span: 4, offset: 0 }} lg={{ span: 2, offset: 0 }} xl={{ span: 2, offset: 0 }}>
					<a href="http://twitch.krelsis.net">
						<div id="TwitchIcon" className="TransitionImage SocialIcon"> </div>
					</a>
				</Col>
				<Col xs={{ span: 5, offset: 1 }} sm={{ span: 4, offset: 0 }} md={{ span: 4, offset: 0 }} lg={{ span: 2, offset: 0 }} xl={{ span: 2, offset: 0 }}>
					<a href="http://youtube.krelsis.net">
						<div id="YoutubeIcon" className="TransitionImage SocialIcon"> </div>
					</a>
				</Col>
				<Col xs={{ span: 5, offset: 0 }} sm={{ span: 4, offset: 0 }} md={{ span: 4, offset: 0 }} lg={{ span: 2, offset: 0 }} xl={{ span: 2, offset: 0 }}>
					<a href="http://facebook.krelsis.net">
						<div id="FacebookIcon" className="TransitionImage SocialIcon"> </div>
					</a>
				</Col>
				<Col xs={{ span: 4, offset: 4 }} sm={{ span: 4, offset: 4 }} md={{ span: 4, offset: 4 }} lg={{ span: 2, offset: 0 }} xl={{ span: 2, offset: 0 }}>
					<a href="http://twitter.krelsis.net">
						<div id="TwitterIcon" className="TransitionImage SocialIcon"> </div>
					</a>
				</Col>
			</Row>
		</Container>
	);
};

export default SocialBar;