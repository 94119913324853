import React, { FunctionComponent } from "react";
import ReactTemplate from "../AboutComponents/CreatedWithReactTemplate";
import Gaming from "../AboutComponents/Gaming";
import Coding from "../AboutComponents/Coding";
import ContentCreation from "../AboutComponents/ContentCreation";

const About: FunctionComponent = () => {
	return (
		<div>
			<ReactTemplate>
				<div className="mx-auto fitcontent">
					<p>
						This application serves as a portal for personal use.
					</p>
					<h5>What can I do here? : </h5>
					<p>
						Nothing :( As mentioned this is place for personal use, not professional.<br/>
						This application serves no other purpose other than prettying up the domain. <br/>
						Actual usage of this domain lies elsewhere for the time being (on a different sub-domain).
					</p>
				</div>
				<hr className="white my-5" />
				<div className="mx-auto fitcontent text-start">
					<h3 className="d-flex justify-content-center">About me : </h3>
					<h5 className="d-flex justify-content-center">What do I do in my spare time? </h5>
					<ul className="text-start">
						<Gaming />
						<Coding />
						<ContentCreation />
					</ul>
				</div>
				<hr className="white my-5" />
				<div className="mx-auto fitcontent text-start">				
					<h5 className="d-flex justify-content-center">What PC specs do I have? </h5>
					<h6 className="d-flex justify-content-center"><a href="http://pc-specs.krelsis.net">Click here to find out!</a></h6>
				</div>
			</ReactTemplate>
		</div>
	);
};

export default About;
