import React, { FunctionComponent } from "react";
import { Nav, Navbar } from "react-bootstrap";
import NavLink from "./NavBarControls/NavLink";
import NavBrand from "./NavBarControls/NavBrand";
import { withRouter, RouteComponentProps } from "react-router";
import NavDropdownButton from "./NavBarControls/NavDropdownButton";
import { ITitle } from "../../Types";
import { useAppSelector } from "../../Store";

interface INavBarProps extends RouteComponentProps, ITitle { };

const NavBar: FunctionComponent<INavBarProps> = ({ title }) => {

	const PositionLocked = useAppSelector((state) => state.NavBar.PositionLocked);

	const NavLockClass = () => (PositionLocked ? " FixedNav" : "");

	return (
        <Navbar
            id="TopNavBar"
            bg="dark"
            variant="dark"
            expand="md"
            className={"bg-dark-indigo" + NavLockClass()}
        >
            <NavBrand />
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto NavBarCenter">
                    <NavLink to="/Home" title="Home" />
                    
                    <NavDropdownButton
                        title={title}
                        className="NavBarDropdownRightSide btn-primary-override"
                    />
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default withRouter(NavBar);