import React, { FunctionComponent } from "react";
import logo from "../../../Assets/Images/krelsis.svg";
import { Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const NavBrand: FunctionComponent = () => {
    return (
        <LinkContainer to="/Home" className="PointerCursor">
            <div>
                <Navbar.Brand>
                    <img src={logo} className="NavLogo" alt="" />
                </Navbar.Brand>
                <Navbar.Brand>
                    <div className="NavBrandName">Krelsis.net</div>
                </Navbar.Brand>
            </div>
        </LinkContainer>
    );
};

export default NavBrand;
