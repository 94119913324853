import React, { FunctionComponent } from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { ITitle, ITo } from "../../../Types";

interface INavLinkProps extends ITitle, ITo { };

const NavLink: FunctionComponent<INavLinkProps> = ({ to, title }) => {
    return (
        <LinkContainer to={to}>
            <Nav.Link>{title}</Nav.Link>
        </LinkContainer>
    );
};

export default NavLink;
