import React, { FunctionComponent, ReactNode } from "react";
import logo from "../../Assets/Images/Components/ReactTemplate/logo.svg";

interface IFunctionComponentProps {
	children: ReactNode
};

const ReactTemplate: FunctionComponent = ({ children }) => {
	return (
		<div className="text-center">
			<div className="ReactTemplate">
				<img src={logo} className="React-logo" alt="logo" />
				<p className="ExtraLargeFontSize">
					This application was created using{" "}
					<a
						className="App-link"
						href="https://reactjs.org"
						target="_blank"
						rel="noopener noreferrer"
					>
						React.JS
					</a>
					.
				</p>
				<p className="SmallFontSize">Created by Josh Arran.</p>
				<hr className="white" />
				{children}
			</div>
		</div>
	);
};

export default ReactTemplate;
