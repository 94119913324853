import React, { FunctionComponent } from "react";
import { Dropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { ITitle, ITo } from "../../../Types";

interface IDropdownLinkProps extends ITitle, ITo { };

const DropdownLink: FunctionComponent<IDropdownLinkProps> = ({ to, title }) => {
    return (
        <LinkContainer to={to}>
            <Dropdown.Item>{title}</Dropdown.Item>
        </LinkContainer>
    );
};

export default DropdownLink;
