import React, { FunctionComponent } from "react";
import { Redirect, Route, Switch } from "react-router";
import Home from "./Components/Views/Home";
import Layout from "./Components/Views/Layout";
import NotFound from "./Components/Views/NotFound";
import About from "./Components/Views/About";

const Routes: FunctionComponent = () => {
	return (
		<Switch>
			<Route exact path="/"
				render={(props) => <Layout {...props}><Home /></Layout>}
			/>
			<Route path="/Home"
				render={(props) => <Layout {...props}><Home /></Layout>}
			/>
			<Route path="/About"
				render={(props) => <Layout {...props}><About /></Layout>}
			/>
			<Route path="/NotFound"
				render={(props) => <Layout {...props}><NotFound /></Layout>}
			/>
			<Redirect to="/NotFound" />
		</Switch>
    );
};

export default Routes;
