import React, { FunctionComponent } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { IOptionalClassName } from "../../../Types";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { TogglePositionLock } from "../../../Store/NavBar";

interface ISiteSettingsDropdownProps extends IOptionalClassName { };

const SiteSettingsDropdown: FunctionComponent<ISiteSettingsDropdownProps> = ({ className }) => {

	const PositionLocked = useAppSelector((state) => state.NavBar.PositionLocked);
	const Dispatch = useAppDispatch();

	const _togglePositionLock = () => {
		Dispatch(TogglePositionLock());
	}

    return (
        <DropdownButton
            title="Site Settings"
            id="dropdown-site-Settings-button"
            className={`${className ?? ""} AccountDropdown SiteSettingsDropdown`}
		>
			<Dropdown.Item onClick={_togglePositionLock}>
				Lock Header : {PositionLocked.toString()}
            </Dropdown.Item>
        </DropdownButton>
    );
};

export default SiteSettingsDropdown;
