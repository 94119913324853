import React, { FunctionComponent } from "react";
import { IReactNodeChildren } from "../../Types";
import { useAppSelector } from "../../Store";

interface IWrapperProps extends IReactNodeChildren { };

const Wrapper: FunctionComponent<IWrapperProps> = ({ children }) => {

	const PositionLocked = useAppSelector((state) => state.NavBar.PositionLocked);

	const WrapperClasses = () => `container mw-90 bg-semi-transparent rounded-xl text-white py-3 ${PositionLocked ? "my-9" : "my-6"}`;

	return (
		<div id="Wrapper"
			className={WrapperClasses()}
		>
			{children}
		</div>
	);
};

export default Wrapper;
