import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

let CurrentYear = new Date().getFullYear();

const Footer: FunctionComponent = () => {
	return (
		<footer className="page-footer">
			<div>
				© 2016 ~ {CurrentYear} Copyright: Josh Arran |
				<Link to="/Home">&nbsp;Krelsis.net</Link>
			</div>
		</footer>
	);
};

export default Footer;
