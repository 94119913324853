import React, {FunctionComponent} from "react";
import SocialBar from "../SocialComponents/SocialBar";

const Home: FunctionComponent = () => {
	return (
		<div>
			<h1 className="d-flex justify-content-center">Welcome!</h1>
			<hr className="white" />
			<br />
			<p className="text-center">
				This page serves no real purpose other than to pretty up my domain!
				Yep, sorry, there really is nothing to do here! <br />
				You can find out more about this application by visiting the about page by clicking the drop down at the top right.
			</p>
			<hr className="white my-5" />

			<h5 className="mb-5 d-flex justify-content-center">Since there's nothing else to do here, why not check out the social sites below?</h5>
			<SocialBar />
		</div>
	);
};

export default Home;
